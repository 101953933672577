:root {
  /* backgrounds  */
  /* --background-color : #9BE8D8; */
  --background-color : #322537;
  --footer-bakcground-color : #000;
  --itinerary-select-background : #f0f8ff;
  --inner-panel-background : #ace8ff;
  /* --country-name-color : #343434; */
  --country-name-color : #eee7d3;
  /* --country-gradient-color-start : rgba(255, 255, 255, 1);
  --country-gradient-color-end : rgba(255, 255, 255, .75); */
  --country-gradient-color-start : rgba(0, 0, 0, 1);
  --country-gradient-color-end : rgba(0, 0, 0, .75);
  /* buttons  */
  --button-color : #4c57d2;
  --button-color-hover : #8992f5;
  /* graphics  */
  --spinner-color : #5c5c5c;

  --marker-text-background : #9fc3ca;

  /* --display-panel-bg : #7347cc;
  --display-panel-fg : #EEA5A6; */
  --display-panel-bg : #78C1F3;
  --display-panel-fg :  #35382e; 
  /* --display-panel-fg :  #F8FDCF; */
  --map-message-background : #5a0634;
  --map-message-fg : #fff;

  /* errors  */
  --error-message-color : rgb(199, 96, 96);

  /* links  */
  --link-color : #362bcb;
  --link-color-hover : #4639ff;
  
}

html,body {
  background: var(--background-color);
}

p.margin-10 {
  margin : 10px;  
}

p.margin-0 {
  margin : 0;  
}

.home-button {
  position: absolute;
  top: 10px;
  left: 10px;
  width: 70px;
  height: 70px;
  cursor: pointer;
  z-index: 3000;
}

.home-button img {
  cursor: pointer;
  z-index: 3000;
}


.App {
  text-align: center;
  max-width: 900px;
  margin: auto;
  background: #fff;
  min-height: 100vh;
}

.content {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  justify-content: space-between;
}

footer {
  padding-top: 10px;
  background: var(--footer-bakcground-color);
  color: #fff;
}

footer a,
footer a:visited {
  text-decoration: none;
  color: #fff;
}

footer hr {
  width: 40%;
}

a.btn,
button {
  border: none;
  border-radius: 4px;
  box-shadow: 2px 2px 2px 2px #00000042;
  padding: 6px;
  color : #fff;
  background: var(--button-color);
  cursor: pointer;
  transition: background 1s;
}

a.btn:hover,
button:hover {
  background: var(--button-color-hover);
  transition: background 1s;
}

a.btn {
  text-decoration: none;
} 

.button-bar-horizontal {
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin: 6px 0;
}


.button-bar-horizontal button {
  margin: 0 10px;
  min-width: 80px;
}

input {
  height: 24px;
  width: 200px;
}

select {
  width: 80%;
  height: 24px;
  margin: 10px auto;
}

.link {
  cursor: pointer;
  color: var(--link-color);
  transition: color .25s ease-in-out;
  text-decoration: none;
}

.link:hover {
  color : var(--link-color-hover);
  transition: color .25s ease-in-out;
}

/* ERRORS  */
.error,
.error-panel .error{
  color: var(--error-message-color) !important;
}

/* FORMS  */

.form-group {
  display: flex;
  width: 100%;
  margin: 4px;
  flex-direction: row;
  text-align: left;
  justify-content: space-between;
  align-items: center;
}

/* LAYOUT  */


.display-panel {
  background: var(--display-panel-bg);
  color: var(--display-panel-fg);
  margin: 20px;
  border-radius: 10px;
}

.hidden {
  display: none;
}

.modal.hidden {
  display: none;
}

 /* dialog styles  */

 .backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background: rgba(0, 0, 0, 0.75);
  z-index: 2000;
}

.modal {
  display: flex;
  flex-direction: column;
  padding: 1rem;
  position: fixed;
  top: 10%;
  left: 18.5%;
  width: 60%;
  max-height: 90vh;
  background: white;
  border-radius: 5px;
  z-index: 3000;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.26);
  overflow: hidden;
}

.modal .dialog {
  width: 100%;
}

.modal .dialog h2 {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 70%;
  text-align: left;
  margin: auto;
}

.modal.custom-modal {
  top: 5%;
}

.modal.add-itinerary {
  min-height: 400px;
  max-height: 600px;
}


/* .modal .header {
  border-bottom: 1px solid #ccc;
} */

.modal .body {
  /* overflow-y: scroll; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  max-height: 80vh;
  min-height: 20vh;
}

.modal .body  p {
  color: var(--text-color);
}

.modal .header h1 {
  text-align: center;
}

/* modal button sets  */
.modal .button-set {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 50%;
}

.modal .button-set.test-select .btn {
  width: 5rem;
  height: 5rem;
  font-size: 1rem;
  padding: 6px;
  background-color: var(--signin-button-color);
}

.modal .button-set.test-select .btn:hover {
  transition: background 1s;
  background: var(--signin-button-color-hover);
}

.modal.change-start-date {
  min-height: 400px;
}

/* CREATE ITINERARY MODAL  */
.itinerary-items {
  display: flex;
  flex-direction: column;
}

.itinerary-items .rows {
  max-height: 200px;
  overflow-y: scroll;
}

.itinerary-items .itinerary-item {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
}

.itinerary-items .itinerary-item  p:first-child {
  width: 40px;
}

.itinerary-items .itinerary-item  p {
  width: 80px;
  text-align: left;
  overflow-x: hidden;
}

.itinerary-items .itinerary-item  .row-icon-container {
  width: 26px;
  height: 30px;
  cursor: pointer;
  padding: 6px;
}

.itinerary-items .itinerary-item  .row-icon-container  img {
  width: 100%;
  height: 100%;
}

/* CSS BY COMPONENT  */

/* Country Image Banner  */

.country-image-banner {
  position: relative;
  box-shadow: 2px 2px 2px 2px #050505;
  margin-bottom: 2px;
}

.country-image-banner h2 {
  position: absolute;
  top: 0;
  bottom: 0;
  width: 100%;
  font-size: 60px;
  font-weight: bold;
  color: var(--country-name-color);
}

.country-image-banner h2.under-country-name {
  position: absolute;
  top: -2px;
  right: 2px;
  color: #000;
}

.country-image-container {
  position: relative;
  display: block;
  width: 100%;
  height: 150px;
}

.country-image-container::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to bottom, rgb(0 161 232), rgb(255 255 255 / 41%));
  opacity: 0.5;
}

.country-image-container img {
  width: 100%;
  height: 100%;
}


/* .country-title {
  box-shadow: 2px 2px 2px 2px #ff0000;
}

.country-title h2 {
  position: absolute;
  font-size: 20px;
}

.country-title h2.shaddow {
  font-size: 30px;
  color: #ff0000;
} */

/* Loading Widget  */

/* GRAPHICAL EFFECTS  */
.lds-dual-ring {
  display: inline-block;
  width: 60px;
  height: 60px;
}

.lds-dual-ring:after {
  content: " ";
  display: block;
  width: 40px;
  height: 40px;
  margin: 8px;
  border-radius: 50%;
  border: 12px solid #fff;
  border-color: var(--spinner-color) transparent var(--spinner-color) transparent;
  animation: lds-dual-ring 1.2s linear infinite;
}

.lds-dual-ring.hidden {
  display: none;
}

/* MAP STYLES  */

.numbered-marker {
  position: absolute;
  background-image: url('assets/pin_icon_blue.png');
  background-size: cover;
  width: 30px;
  height: 40px;
  top : -40px;
  left : -30px;
}

.marker-text {
  background: var(--marker-text-background);
  border-radius: 50%;
  margin: 6px auto;
  width: 20px;
  height: 20px;
}
 /* COUNTRY SCREEN  */

 .location-panel {
  display: flex;
  flex-direction: row;
 }

 .location-panel.display-panel .alt-display {
  display: none;
 }

 .country-map-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 50%;
  height: 100%;

 }


 .country-map-panel .loading-container {
  content: 'hello';
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
 }
 

 .country-map-panel .loading-container::after  {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #fff;
  opacity: .6;
  z-index: 1000;
  border-radius: 10px;
 }
 

 .country-map-panel .spin-container{
  position: absolute;
  top: 100px;
  width: 100%;
  margin: auto;
  opacity: 1;
  z-index: 2000;
 }


 .country-map {
  position: relative;
  width: 400px;
  height: 400px;
  margin: 20px;
 }

 .country-map img {
  width: 100%;
  height: 100%;
  border-radius: 10px;
 }


 .leaflet-container {
  border-radius: 10px;
 }

.map-click-message-container {
  position: absolute;
  top: 0;
  z-index: 1000;
  width: 100%;
}

 .map-click-message {
  width: 70%;
  margin: 10px auto;
  padding: 6px;
  border-radius: 10px;
  background-color: var(--map-message-background);
  color: var(--map-message-fg);
 }

.location-info-panel {
  width: 50%;
  max-height: 600px;
}

.location-info-panel .itinerary-options {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.location-info-panel .itinerary-options .btn {
  width: 100%;
}
.location-scroll {
  background: var(--inner-panel-background);
  overflow-y: scroll;
  height: 250px;
  margin: 10px;
}

/* HOME SCREEN  */

.country-info-panel {
  height: 250px;
}

.country-description {
  padding: 20px;
}

.capital-detail {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.capital-detail div {
  margin: 0 10px;
}

.world-map {
  width: 98%;
  margin: auto;
  height: 300px;
}

.home-itinerary-select {
  display: flex;
  flex-direction: column;
  background: var(--itinerary-select-background);
  padding: 10px;
}

.home-itinerary-select .btn{
  width: 30%;
  margin: auto;
}

/* ITINERARIES SCREEN  */

.itinerary-detail-panel {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
}

.itinerary-detail-panel .btn {
  width: 80%;
}

.itinerary-detail-panel h1 {
  height: 100px;
}

.itinerary-content {
  min-height: 700px;
}

.itinerary-date-range {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.itinerary-content p:nth-child(2) {
  padding: 0 10px;
}

.itineraries-layout {
  display: flex;
  flex-direction: row;
  max-height: 590px;
}

.itineraries-layout .country-map-container {
  display: flex;
  width: 50%;
  justify-content: center;
}

.itinerary-detail-panel {
  width: 50%;
}

.itinerary-items-container {
  height: 60%;
}

.itinerary-items-panel {
  width: 90%;
}

.itinerary-items-list {
  background: var(--inner-panel-background);
  overflow-y: scroll;
  height: 100%;
}

.itinerary-items-list .location-item {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  padding: 2px 4px;
}

.itinerary-items-list .location-item p:nth-child(1){
  width: 10px;
}

.itinerary-items-list .location-item p:nth-child(2){
  width: 90px;
  overflow: hidden;
}

.itinerary-items-list .location-item p:nth-child(3){
  width: 70px;
  overflow: hidden;
}

.itinerary-items-list .location-item p:nth-child(4){
  width: 70px;
  overflow: hidden;
}

/* PRIVACY POLCIY  */

.privacy-policy {
  padding: 10px;
}

.privacy-policy ul {
  list-style-type: none;
}


/* CALENDAR / DATEPICKER COMPONENT  */
.date-input {
  width: 100%;
  display: flex;
  justify-content: flex-end;
}

.date-input svg {
  width: 24px;
  height: 24px;
  margin: 2px;
  cursor: pointer;
}

.calendar-display {
  position: absolute;
  left: 20%;
  width: 60%;
  top: 10%;
  background-color: #2a80b9;
  z-index: 3000;
  border-radius: 10px;
}

.calendar-display .nav-month {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 0 6px;
  margin: auto;
  width: 240px;
}

.calendar-display .day-grid {
  /* display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr; */
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 6px;
  height: 240px;
}

.calendar-display .day-grid .calendar-row {
  display: flex;
  flex-direction: row;
}

.calendar-display .day-grid .day-name {
  background-color: #2e2e2e;
  color: #fff;
  width: 20px;
  padding: 6px;
  height: 20px;
}

.calendar-display .day-grid .day-name.month-day {
  background: #fff;
  color: #2e2e2e;
  cursor: pointer;
  /* transition: background 1s;  */
}

.calendar-display .day-grid .day-name.month-day.selected {
  background: #729db1;
}

.calendar-display .day-grid .day-name.month-day:hover {
  background: #bacaf6;
  color: #2e2e2e;
  cursor: pointer;
  transition: background 1s;
}

.calendar-display .day-grid .day-name.non-day {
  background: #606060;
}

.btn.calendar-nav-btn {
  height: 30px;
  width: 30px;
  box-shadow: none;
  padding: 4px;
}

.year-month-display p {
  text-align: center;
  font-weight: bold;
}

@keyframes lds-dual-ring {
  0% {
      transform: rotate(0deg);
  }
  100% {
      transform: rotate(360deg);
  }
}


/* MEDIA QUERIES  */

@media (max-width: 850px) {
  .location-panel {
    flex-direction: column;
    align-items: center;
  }

  /* show when screen is resized  */
  .location-panel.display-panel .alt-display {
    display: block;
   }
  

  .location-info-panel .alt-display {
    display: none !important;
  }

  .itineraries-layout.display-panel {
    align-items: center;
    flex-direction: column-reverse;
  }

  .itineraries-layout {
    max-height: fit-content;
  }

  .itinerary-detail-panel {
    width: 90%;
  }

  .itineraries-layout .country-map-container {
    width: 90%;
  }
}

@media (max-width: 550px) {
  .modal {
    top : 0;
    left : 0;
    width: 100%;
  }

  .modal .body {
    width: 90%;
  }

  .modal .dialog {
    width: 96vw;
  }

  .itinerary-items .itinerary-item  p:nth-child(3) {
    display: none;
  }

  .country-image-container {
    height: 230px;
  }

  .display-panel {
    margin: 0;
  }

  .country-map {
    position: relative;
    width: 300px;
    height: 300px;
    margin: 20px;
  } 

  .map-click-message {
    width: 60%;
  }

  .location-info-panel {
    width: 100%;
    max-height: 100%;
  }

  .calendar-display {
    left: 0;
    width: 100%;
  }

  .country-info-panel {
    height: 100%;
  }

  .country-image-banner h2.under-country-name {
    top: 15px;
  }

  .country-image-banner h2 {
    top: 16px
  }

  .home-button {
    z-index: 2000;
  }

  .itineraries-layout.display-panel {
    margin-top: 20px;
  }

  .itinerary-items-panel {
    width: 100%;
  }
}